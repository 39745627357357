import React, { Component, useEffect } from 'react'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'
import { withRouter } from 'react-router-dom'
import session from '../stores/session'

export default withRouter(class AdminVersionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changes: null,
            versionNumbers: null,
            loading: true,
            editInput: null,
            editMode: false,
            noteToEdit: null,
            selectedVersionNumber: null,
            limit: 5,
            newNoteInput: null,
            newVersionCode: null,
            createNewNoteMode: false,
            versionCode: null
        }
    }

    componentDidMount() {
        Promise.all([
            request(true).get(endpoints.ADMIN_VERSION_HISTORY_CHANGES),
            request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS)
        ]).then(([changes, versionNumbers]) => {
            if (!versionNumbers.data || versionNumbers.data.length == 0) window.alert("No results found");
            this.setState({
                loading: false,
                changes: changes.data,
                versionNumbers: versionNumbers.data,
                selectedVersionNumber: versionNumbers.data[0]
            })
        })
        if (session.company) {
            request(true).post(endpoints.ADMIN_VERSION_HISTORY_SEEN_CHANGES);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.limit !== this.state.limit || prevState.selectedVersionNumber !== this.state.selectedVersionNumber) {
            this.updateResults();
        }
    }

    updateResults = () => {
        request(true).get(endpoints.ADMIN_VERSION_HISTORY_CHANGES + `?Version_Number=${this.state.selectedVersionNumber}&limit=${this.state.limit}`).then(r => {
            this.setState({
                changes: r.data
            })
        })
    }

    setEditMode = (id) => {
        this.state.editMode ?
            this.setState({
                editMode: false,
                noteToEdit: null,
                editInput: null,
                versionCode: null,
            })
        :
            this.setState({
                editMode: true,
                editInput: null,
                versionCode: null,
                noteToEdit: id
            })
    }

    submitChanges = (e) => {
        if (this.state.versionCode && !/^\d+\.\d+\.\d+$/.test(this.state.versionCode)) {
            window.alert('Version codes must follow a semantic versioning pattern such as 1.2.3')
            return
        }
        const changeColumn = e.target.title;
        request(true).post(endpoints.ADMIN_VERSION_HISTORY_EDIT, 
            {
                ID: this.state.noteToEdit, 
                [changeColumn]: this.state.editInput, 
                Version_Number: this.state.versionCode ? this.state.versionCode : this.state.changes[this.state.changes.findIndex(change => change.id === this.state.noteToEdit)].Version_Number
            }).then(r => {
                if (r.status === 200) {
                    request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS).then(r => {
                        this.setState({
                            versionNumbers: r.data,
                            selectedVersionNumber: r.data.includes(this.state.versionCode) ? this.state.versionCode : r.data[0]
                        })
                    }).then(() => {
                        this.setState({
                            noteToEdit: null,
                            versionCode: null,
                            editMode: false
                        });
                        this.updateResults()
                    });
                } else {
                    window.alert('Failed to edit note');
                    this.setState({
                        noteToEdit: null,
                        editMode: null
                    })
                }
        });
    }

    submitNewNote = (e) => {
        if (!this.state.newNoteInput || !this.state.newVersionCode) {
            window.alert('Please ensure your new note has both content and a version code')
            return
        }
        if (!this.state.newVersionCode || !/^\d+\.\d+\.\d+$/.test(this.state.newVersionCode)) {
            window.alert('Version codes must follow a semantic versioning pattern such as 1.2.3')
            return
        }
        request(true).post(endpoints.ADMIN_VERSION_HISTORY_INSERT, 
            {
                [e.target.title]: this.state.newNoteInput, 
                Version_Number: this.state.newVersionCode
            }).then(r => {
                if (r.status === 200) {
                    request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS).then(r => {
                        this.setState({
                            versionNumbers: r.data,
                            selectedVersionNumber: r.data.includes(this.state.newVersionCode) ? this.state.newVersionCode : r.data[0]
                        })
                    }).then(() => {
                        this.setState({
                            newNoteInput: null,
                            newVersionCode: null,
                            createNewNoteMode: false
                        });
                        this.updateResults()
                    });
                } else {
                    window.alert('Failed to submit note');
                    this.setState({
                        createNewNoteMode: null,
                        noteToEdit: null
                    })
                }
        });
    }

    deleteChanges = (id) => {
        const deleteThis = window.confirm('Are you sure you want to delete this note?');
        if (deleteThis) {
            request(true).post(endpoints.ADMIN_VERSION_HISTORY_DELETE, {ID: id}).then(r => {
                if (r.status === 200) {
                    request(true).get(endpoints.ADMIN_VERSION_HISTORY_VERSION_NUMBERS).then(r => {
                        this.setState({
                            versionNumbers: r.data,
                            selectedVersionNumber: r.data.includes(this.state.selectedVersionNumber) ? this.state.selectedVersionNumber : r.data[0]
                        })
                    }).then(() => {
                        this.updateResults()
                    });
                } else {
                    window.alert('Failed to delete note');
                }
            });
        }
    }

    updateEditInput = (e) => {
        this.setState({
            editInput: e.target.value
        })
    }

    updateNewNoteInput = (e) => {
        this.setState({
            newNoteInput: e.target.value
        })
    }

    updateNewVersionCode = (e) => {
        this.setState({
            newVersionCode: e.target.value
        })
    }

    updateVersionCode = (e) => {
        this.setState({
            versionCode: e.target.value
        })
    }

    enterKeyDown = (e) => {
        if (e.which === 13) {
          if (e.target.name == "edit-note") {
            this.submitChanges();
          }
        }
    }

    render() {
        return (
            <div className="grid grid-gap-20 version-history">
                <Navigation />
                <Container>
                    <h3>Release Notes</h3>
                    <div className='release-notes' >
                        <div className='select-container' >
                            <div className='select-label' >Version Number</div>
                            <select className='version-select' onChange={(_) => this.setState({selectedVersionNumber: _.target.value})} value={this.state.selectedVersionNumber}>
                                {
                                    session.company === null ?
                                        this.state.versionNumbers ? this.state.versionNumbers.map((version) => {
                                            return (
                                                <option value={version}>{version}</option>
                                            )
                                        })
                                        : null
                                    :
                                        this.state.versionNumbers ? this.state.versionNumbers.map((version) => {
                                            return (
                                                <option value={version}>{version}</option>
                                            )
                                        })
                                    : null
                                }
                            </select>
                        </div>
                        <div className='select-container' >
                            <div className='select-label' >Limit</div>
                            <select className='version-select' onChange={(_) => this.setState({limit: _.target.value})} value={this.state.limit}>
                                <option value={1}>1</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                        { session.company === null &&
                            <button className='new-note' onClick={() => {this.setState({createNewNoteMode: true})}}>Create New Version Note</button>
                        }
                    </div>
                    {
                        this.state.changes && !this.state.loading ? 
                        <>
                            <div className={this.state.changes?.length || !session.company ? 'changes-container' : ''} >
                                <div>
                                    {
                                        this.state.changes.map((change) => {
                                            return (
                                                <div style={{borderBottom: 'dashed 1px grey'}}>
                                                    { this.state.editMode && this.state.noteToEdit === change.id ?
                                                        <div>
                                                            <input value={this.state.versionCode ? this.state.versionCode : change.Version_Number} onChange={this.updateVersionCode} style={{display: 'block', fontSize: '1.17em', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', fontWeight: 'bold', width: '20%'}}></input>
                                                            { this.state.versionCode && !/^\d+\.\d+\.\d+$/.test(this.state.versionCode) ? <p style={{color: 'red',  fontSize: 'small'}}>Version codes must follow a semantic versioning pattern such as 1.2.3</p> : null }
                                                            <div style={{position: 'relative'}}>
                                                                <textarea name="edit-note" onChange={ this.updateEditInput } value={this.state.editInput ? this.state.editInput : change.Release_Notes} style={{marginTop:'1em', marginBottom: '1em', minHeight: '20vh', width: '75%', resize: 'none'}}></textarea>
                                                                <span style={{position: 'absolute', bottom: '3vh', right: '10vw', fontSize: 'small'}}>{this.state.editInput ? 255 - this.state.editInput.length : 255 - change.Release_Notes.length} characters remaining</span>
                                                            </div>
                                                            { session.company === null ?
                                                            <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                                <i title="Changes" class="fa-solid fa-check" style={{cursor: 'pointer'}} onClick={(e) => this.state.editInput ? this.state.editInput.length < 256 ? this.submitChanges(e) : window.alert('Version notes too large') : this.submitChanges(e)}></i>
                                                                <i class="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={() => this.setEditMode()}></i>
                                                            </div>
                                                            :
                                                                null
                                                            }
                                                        </div>
                                                    :
                                                        <div>
                                                            <h3>{change.Version_Number ? change.Version_Number : null}</h3>
                                                            <p style={{whiteSpace: 'break-spaces'}}>{change.Release_Notes ? change.Release_Notes : null}</p>
                                                            { session.company === null ?
                                                            <div style={{display: 'flex', gap: '2%', marginBottom: '2%'}}>
                                                                <i class="fa-light fa-pencil" style={{cursor: 'pointer'}} onClick={() => this.state.editMode ? null : this.setEditMode(change.id)}></i>
                                                                <i class="fa-light fa-trash-can" style={{cursor: 'pointer'}} onClick={() => this.state.editMode ? null : this.deleteChanges(change.id)}></i>
                                                            </div>
                                                            :
                                                                null
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div style={{ marginLeft: '2em' }}>
                                    { this.state.createNewNoteMode ?
                                        <div style={{ width: '75%' }}>
                                            <input name="version-code" value={this.state.newVersionCode ? this.state.newVersionCode : null} onChange={this.updateNewVersionCode} placeholder="Version Code" style={{display: 'block', fontSize: '1.17em', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '0px', marginInlineEnd: '0px', fontWeight: 'bold'}}></input>
                                            { this.state.newVersionCode && !/^\d+\.\d+\.\d+$/.test(this.state.newVersionCode) ? <p style={{color: 'red',  fontSize: 'small'}}>Version codes must follow a semantic versioning pattern such as 1.2.3</p> : null }
                                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
                                                <textarea name="Changes" onChange={ this.updateNewNoteInput } value={this.state.newNoteInput ? this.state.newNoteInput : null} style={{marginTop:'1em', marginBottom: '1em', minHeight: '10em', resize: 'none', width: '100%'}} placeholder="Enter changes here"></textarea>
                                                <span style={{position: 'absolute', bottom: '1em', right: '1em', fontSize: 'small'}}>{this.state.newNoteInput ? 255 - this.state.newNoteInput.length : 255} characters remaining</span>
                                            </div>
                                            <div style={{display: 'flex', gap: '2%', marginBottom: '2%', alignItems: 'flex-end'}}>
                                                <i title="Changes" class="fa-solid fa-check" style={{cursor: 'pointer'}} onClick={(e) => this.state.newNoteInput ? this.state.newNoteInput.length < 256 ? this.submitNewNote(e) : window.alert('Version notes too large') : this.submitNewNote(e)}></i>
                                                <i class="fa-solid fa-xmark" style={{cursor: 'pointer'}} onClick={() => this.setState({createNewNoteMode: false})}></i>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </>
                        :
                            <i className='fas fa-loader fa-fw fa-spin' style={{ marginTop: '3em' }}></i>
                    }
                </Container>
                <Footer/>
            </div>
        )
    }
})
