import { store } from '@risingstack/react-easy-state';
import request from '../helpers/request';

if (!sessionStorage.length) {
    sessionStorage.setItem('getSessionStorage', Date.now());
    sessionStorage.removeItem('getSessionStorage');
}

window.addEventListener('storage', (e) => {
    if (e.key === 'getSessionStorage') {
        sessionStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
        sessionStorage.removeItem('sessionStorage');
    } else if (e.key === 'sessionStorage' && !sessionStorage.length) {
        var data = JSON.parse(e.newValue);

        for (let key in data) {
            console.log(key, data[key]);
            sessionStorage.setItem(key, data[key]);
        }

        session.fetch();
        window.location.href = window.location.search.split('?')[1];
    }
});

const session = store({
    token: sessionStorage.getItem('user-token'),
    user: {
        id: null,
        name: null,
        permissions: [],
        bookmarks: []
    },
    company: {
        login_video_url: '',
        vca_video_url: '',
        can_azure_login: false
    },
    fetch: () => {
        return request(true).get('/self').then(e => {
            console.log('fetched');
            session.user.id = e.data.id;
            session.user.name = e.data.name;
            session.user.permissions = e.data.permissions.map(function (item) {
                return item.key;
            });
            session.user.bookmarks = e.data.bookmarks.sort((a, b) => 
                (a.order - b.order + e.data.bookmarks.length) % e.data.bookmarks.length
            );
            session.user.user_type = e.data.user_type;
            session.company = e.data.company;
            console.log(e.data.company);
            return;
        }).catch(e => {
            session.reset();
        });
    },
    reset: () => {
        request(true).get('/logout').then(() => console.log('SESSION: LOGGED OUT')).catch(() => console.log('SESSION: FAILED TO LOGOUT (Already logged out?)'));
        session.token = null;
        sessionStorage.removeItem('user-token');
        session.user = {
            id: null,
            name: null,
            permissions: [],
            bookmarks: [],
        };
        session.company = null;
    },
    isAlive: () => {
        if (session.token) {
            if (session.user.name) {
                return true;
            } else {
                return session.fetch();
            }
        }

        return false;
    }
});

if (session.user.id === null && session.token !== null) {
    session.fetch();
}

export default session;
