import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import session from '../../../stores/session'
import endpoints from '../../../helpers/endpoints'
import ReactModal from 'react-modal'
import { AddressBase, CouncilTax, CMOSLive, Summary, ValuationOffice, CMOSFormatted } from './shared'
import { MDS } from '../OccupierTelephoneValidation/sharedComponents'

export default view(function UndeliverableWorkflow() {
  const [summaryData, setSummaryData] = useState({})
  const [selectedMatches, setSelectedMatches] = useState({abp: '', voa: '', ct: ''})
  const [dataIssue, setDataIssue] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  const [mdsData, setMdsData] = useState([])
  const [mdsDataReady, setMdsDataReady] = useState(true)
  const [customMDSPostcode, setCustomMDSPostcode] = useState('')
  const [loadingConfirm, setLoadingConfirm] = useState(false)

  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=HP.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
    useEffect(() => {
      setSelectedMatches({abp: '', voa: '', ct: ''})
    }, [summaryData.Core_SPID])

  const getMDSData = () => {
    if (!summaryData || !summaryData.Core_SPID) return
    setMdsDataReady(false);
    request(true).get(endpoints.UNDELIVERABLE_MDS_SPID, {
      doesCancel: true,
      params: {
          postcode: customMDSPostcode || summaryData.CF_Postcode || summaryData.CL_Postcode
      }
    }).then(r => {
      setMdsData(r.data)
      setMdsDataReady(true)
    }).catch(e => {
      setMdsDataReady(true)
        console.log(e);
    })
  }

  return (
    <GenericPageContainer
    containerWidth={"95vw"}
      title="Non-Addressable - Workflow"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/undeliverable">
              To Summary
          </Link>
          <Link className="navigate" to="/portal/undeliverable/search">
              To Search
          </Link>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} setMdsData={setMdsData} setMdsDataReady={setMdsDataReady} getMDSData={getMDSData} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <ValuationOffice translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <CouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <CMOSFormatted translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} selectedMatches={selectedMatches} setConfirmModal={setConfirmModal} />
        </div>
        <div>
          <ReactModal 
          isOpen={confirmModal} 
          onRequestClose={() => setConfirmModal(false)}
          className="card bulk-allocate"
          contentLabel="Wholesaler GAPs No Match"
          style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
              <h3>{dataIssue ? dataIssue : "Confirm"}</h3>
              {
                dataIssue ? 
                  <p>Please confirm you wish to report this SPID as {dataIssue}.</p>
                :
                  <p>Please confirm you are finished working on this SPID.</p>
              }
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <button className="button compact smaller-text cancel" onClick={ () => setConfirmModal(false) }>Cancel</button>
                <button className="button compact smaller-text background-primary colour-white" onClick={() => {
                  setLoadingConfirm(true)
                  request(true).post(endpoints.UNDELIVERABLE_SELECT_MATCH, {
                    spid: summaryData.Core_SPID,
                    data_issue: dataIssue ?? ''
                  }).then(r => {
                    setLoadingConfirm(false)
                    setSummaryData({})
                    window.reloadWorkflow()
                    setConfirmModal(false); 
                  }).catch(error => {
                    console.log(error)
                    setLoadingConfirm(false)
                  })
                }}
                >
                  { !loadingConfirm ?
                      "Confirm"
                    :
                      <div className="spinner" style={{ margin: '0em 1em' }}>
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                  }
                </button> 
              </div>
            </div>
          </ReactModal>
        </div>
        <MDS data={ mdsData } MDSDataReady={ mdsDataReady } getData={ getMDSData } customPostcode={ customMDSPostcode } setCustomPostcode={ setCustomMDSPostcode } style={{ fontSize: '14px' }} classes={"squish"} /> 
        <div style={{minHeight: '40vh', marginBottom: '10em'}}>
          <PropertyMap 
          translations={translations} 
          address={summaryData['CMOS_Address_Single_Line']} 
          meterCoords={summaryData && summaryData.GISX && summaryData.GISY && [parseInt(summaryData.GISX), parseInt(summaryData.GISY)]} 
          cmosMeterRecenter={true} />
        </div>
      </div>
    </GenericPageContainer>    
  )
})
