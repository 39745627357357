import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation';
import ProductSelect from '../../components/product-select/productSelect'
import request from '../../helpers/request';
import session from '../../stores/session'

export default function Groupings() {
    const [product, setProduct] = useState(undefined);
    const [groupings, setGroupings] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setProduct(session.groupSelectedProduct)
    }, [])

    const getDataGroupings = useCallback(() => {
        setLoading(true)

        request(true).get('/datagroupings', {
            params: {
                ...(product && { real_product_id: product }),
            }
        }).then(e => {
            setGroupings(e.data)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setGroupings([])
            setLoading(false)
            window.alert("Could not retrieve groups")
          })
    }, [product])

    const addQueryData = useEffect(() => {
        session.groupSelectedProduct = product
        if (product !== undefined) {
          getDataGroupings();
        } else {
          setGroupings([])
        }
      }, [product])

    const deleteGroup = (id) => {
        if (!window.confirm('Delete grouping?')) return
        request(true).delete('/datagroupings/'+id).then(() => {
            getDataGroupings()
        })
    }

    const copyGroup = (id) => {
      request(true).post(`/datagroupings/${id}/copy`, {
        newName: window.prompt('Copy Name Key')
      }).then(() => {
        getDataGroupings()
        window.alert('Coppied')
      }).catch(() => {
        window.alert('Failed to copy')
      })
    }

    return (
        <div className="grid grid-gap-20">
            <Navigation />
            <Container>
                <div className="flex middle">
                    <p className="colour-secondary font-weight-600" style={{ fontSize: 20 }}>Data Groupings</p>
                    <div className="flex" style={{ marginLeft: 'auto', gap: '10px' }}>
                        <div>
                            <Link to="/admin/portal/groupings/new"><button className="button compact background-primary colour-white">New Data Grouping</button></Link>
                        </div>
                        <div style={{display: 'flex'}}>
                            <ProductSelect product={product} onChange={(e) => setProduct(e ? e.value : undefined)} />
                        </div>
                    </div>
                </div>
            </Container>
            <Container>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Internal Name</th>
                            <th>Title</th>
                            <th>Target</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            loading ? 
                                <td colSpan={4} style={{ textAlign: 'center' }} >
                                    <i className="fa fa-spinner fa-spin" style={{ margin: '1em' }} />
                                </td>
                            : ''
                        }
                        {
                            groupings.map(grouping => {
                                return (
                                    <tr key={grouping.id}>
                                        <td>{grouping.internal_name}</td>
                                        <td>{grouping.title}</td>
                                        <td>{grouping.target_column}</td>
                                        <td>
                                            <div className="grid grid-columns-3 grid-gap-5">
                                                <Link to={`/admin/portal/groupings/${grouping.id}`}><button className="button compact background-primary colour-white">Edit</button></Link>
                                                <button onClick={() => deleteGroup(grouping.id)} className="button compact background-red colour-white">Delete</button>
                                                <button className='button compact' onClick={() => copyGroup(grouping.id)}><span className='fas fa-copy'></span></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Container>
        </div>
    )
}
