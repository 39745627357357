import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import GenericPageContainer from '../../../../GenericPageElements/GenericPageContainer'
import request from '../../../../helpers/request'
import hasPermission from '../../../../helpers/permissions'
import { useHistory } from 'react-router-dom';
import BatchRunModal from "../BatchRunModal";
import { Summary } from './shared'

export default view(function WholesalerGAPSSearch() {
  const history = useHistory();
  const postcode = history.location?.state?.postcode || null;
  const [translations, setTranslations] = useState({})
  const [bulkModel, setBulkModel] = useState('');
  useEffect(() => {
    request(true).get('/translate?key=WSG.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  return (
    <GenericPageContainer
      containerWidth="92vw"
      title="VOA Matching"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}

      titleRight={
        <div style={{marginBottom: '10px'}}>
          <div className="stage-block">
            <button onClick={() => history.push({pathname: "/portal/wholesaler-gaps", state: { postcode: postcode }})}>Stage 1</button>
            <button className="active-stage">Stage 2</button>
            { hasPermission('WHOLESALER_GAPS', 'BULK-BUY') ?
              <button onClick={() => setBulkModel('batchrun')}>Stage 3</button>
            : 
              <button>Stage 3</button>
            }
            <button onClick={() => history.push({pathname: "/portal/commercial-gaps/", state: { postcode: postcode }})}>Stage 4</button>
          </div>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/wholesaler-voa-gaps/", state: { postcode: postcode }})}>To Summary</button>
          { hasPermission('WHOLESALER_GAPS', 'WORKFLOW') ?
            <button className="navigate" onClick={() => history.push({pathname: "/portal/wholesaler-voa-gaps/workflow", state: { postcode: postcode }})}>To Workflow</button>
          : null }
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} />
        </div>
      </div>
      <BatchRunModal modalIsOpen={bulkModel === 'batchrun'} closeModal={() => setBulkModel('')} postcode={postcode} />
    </GenericPageContainer>
  )
})
