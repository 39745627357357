import session from "../stores/session";

export default function hasPermission(module, permission) {
    if (session.user.user_type === 'Team Leader') {
        return true;
    } else if ((session.user.user_type === 'Agent' && permission === 'WORKFLOW')) {
        return true;
    } else if (session.user.permissions.indexOf(module + '-' + permission) !== -1) {
        return true
    } else {
        return false;
    }
}
