import React from 'react';
import Modal from 'react-modal';
import { useEffect } from 'react';
import request from '../../helpers/request';
import './bulkAllocate.scss';
import Select from 'react-select';

export default function BulkBuy ({ modalIsOpen, closeModal, filters, date, bulkEndpoint, unallocateEndpoint, workflowUsersEndpoint, mode, urlParams, title,  ...props })
{
    const [loading, setLoading] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [bulkCount, setBulkCount] = React.useState(0);
    const [bulkLimit, setBulkLimit] = React.useState(0);
    const [buyNowEnabled, setBuyNowEnabled] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const [targetUser, updateTargetUser] = React.useState(null);
    const [search, setSearch] = React.useState('')
    const [loadingUsers, setLoadingUsers] = React.useState(false)
    const [error, setError] = React.useState(undefined);
    const urlParamsString = urlParams ?  "?" + Object.keys(urlParams).map((key, index) => {
        return (
            `${key}=${urlParams[key]}` + (Object.keys(urlParams).length === index ? '' : '&')
        )
    }).join('') : ''

    useEffect(() => {
        setStep(1);
    }, [modalIsOpen, setStep]);

    const getLimit = () => {
        if (step === 1 && modalIsOpen) {
            setLoading(true)
          request(true).get(mode === "unallocate" ? unallocateEndpoint : bulkEndpoint + (urlParamsString ?? ''), {
                doesCancel: true,
                params: {
                    date: date,
                    filter: filters,
                    mode: mode,
                    target: targetUser?.value ?? null,
                }
            }).then(r => {
                setBulkCount(r.data);
                setBulkLimit(r.data);
                setLoading(false)
            }).catch(e => {
                console.log(e);
                setLoading(false)
                window.alert("Failed to retrieve data")
            })
        }
    }

    useEffect(() => {
        if (mode === "unallocate") {
            getLimit()
        }
    }, [targetUser])

    useEffect(() => {
        getLimit()
    }, [step, modalIsOpen, filters, date, bulkEndpoint])

    const confirmPurchase = () => {
        setBuyNowEnabled(false);
        buy();
    }

    const buy = () => {
        request(true).post(mode === "unallocate" ? unallocateEndpoint : bulkEndpoint + '/allocate' + urlParamsString ?? '', {
            date: date,
            filter: filters,
            limit: bulkLimit,
            target: targetUser.value,
            mode: mode
        }).then(r => {
            window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const formChange = (value = 0) => {
        value = Number(value)
        if (value === "") return setBulkLimit(0)

        if (value > bulkCount) {
            value = bulkCount;
        }

        setBulkLimit(value);
    }

    const firstBuyStep = () => {
        console.log('firstBuyStep', bulkLimit, bulkLimit === 0, bulkLimit === '0')
        if (bulkLimit === 0) return setError(`You cannot ${mode === "unallocate" ? "unallocate" : "allocate"} 0 customers, you must ${mode === "unallocate" ? "unallocate" : "allocate"} 1 or more.`)
        setError(undefined)
        setStep(2)
    }

    const searchForUsers = () => {
        setLoadingUsers(true)
        request(true).get(workflowUsersEndpoint, {
            doesCancel: true,
            params: {
                mode: mode,
                search: search
            }
        }).then(r => {
            setUsers(r.data);
            setLoadingUsers(false)
        }).catch(e => {
            console.error(e);
            setLoadingUsers(false)
            window.alert("Could not fetch users")
        })
    }

    useEffect(() => {
        searchForUsers()
    }, [workflowUsersEndpoint, mode, search])

    return (
        <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card bulk-allocate"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Bulk Allocate COT Alert Data"
                    appElement={document.getElementById('root')}
            >
            { step === 1 ?
                <>
                    {title ? <h3>{title}</h3> : ''}
                    <p>
                        The selected data set includes { bulkCount } customers. How many would you like to {mode === "unallocate" ? "unallocate" : "allocate"}?
                    </p>
                    <small>
                        If you reduce this number then customers with higher occupier scores will be {mode === "unallocate" ? "unallocated" : "allocated"} first.
                    </small>
                    {mode !== "unallocate" ?<small>
                        Customers with an occupier score of 0 cannot be allocated.
                    </small>: ''}
                    {error ? <p className="colour-yellow"><strong>{error}</strong></p> : null} 
                    <div className="input">
                        <label>{mode === "unallocate" ? "Unallocate" : "Allocate"} Limit:</label>
                        <div style={{display: 'flex', position: 'relative'}}>
                            <input type={!loading ? "text" : "number"} name="bulk-limit" max={ bulkCount } value={ !loading ? bulkLimit : '' } disabled={loading} onChange={ (e) => formChange(e.target.value) } style={{flex: '1'}}/>
                            { loading ? <div className="spinner" style={{ position: 'absolute', paddingLeft: '8px' }}>
                                <i className="fa fa-spinner fa-spin"></i>
                            </div> : ''}
                        </div>
                    </div>
                    <div className="input">
                        <label>{mode === "unallocate" ? "Unallocate From" : "Allocate To"}:</label>
                        <Select 
                        options={users} 
                        onChange={ updateTargetUser }
                        onInputChange={(value) => {
                            if (value !== search) {
                                setSearch(value)
                            }
                        }}
                        inputValue={search}
                        loading={loadingUsers}
                        escapeClearsValue={false}
                        />
                    </div>
                    <div className="grid-2">
                        <button className="button cancel" onClick={ closeModal }>Cancel</button>
                        <button className="button confirm" onClick={ () => firstBuyStep() } disabled={targetUser === null}>Confirm</button>
                    </div>
                </>
            :
            <>
                <p>
                    You have selected { bulkLimit } accounts to {mode === "unallocate" ? "unallocate from" : "allocate to"} { targetUser.label }, please press '{mode === "unallocate" ? "Unallocate" : "Allocate"} Now' to {mode === "unallocate" ? "unallocate" : "allocate"} these accounts.
                </p>
                <div className="grid-2">
                    <button className="button cancel" onClick={ closeModal }>Cancel</button>
                    <button className="button confirm" onClick={ confirmPurchase } disabled={ !buyNowEnabled }>
                        {
                            !buyNowEnabled ?
                                <div className="spinner">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </div>
                            : `${mode === "unallocate" ? "Unallocate" : "Allocate"} Now`
                        }
                    </button>
                </div>
            </>
            }
        </Modal>
    )
}
