import React, { Component, useEffect } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './occupiedSaleAlert.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class OccupiedSaleAlertSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: null,
                }
            }

            componentDidMount = () => {
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=osa.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                request(true).get(`${endpoints.OCCUPIED_SALE_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    // console.log(r.data.customer, r.data.customer ? '' : 'Customer Does Not Exist');
                    if (!r.data.customer) window.alert('Account not found.');
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null && r.data.customer.Deed_Status === 'Deed Available',
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                request(true).post(`${endpoints.OCCUPIED_SALE_ALERTS_BUY_CUSTOMER}`, {
                    "customer": this.state.customer.Account_Number
                }).then(r => {
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null
                    });
                }).catch(e => {
                    console.log(e);
                })
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('occupied_cot_alert', 'unlisten');// eslint-disable-line
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Occupied Sale Alerts - Search" titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to="/portal/occupied-sale-alert/">
                                        To Overview
                                    </Link>
                                    { (hasPermission('OSA', 'WORKFLOW')) ?
                                        <Link className="navigate" to="/portal/occupied-sale-alert/workflow">
                                            To Workflow
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="ov">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['osa.AccountNumber'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                        </div>
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            this.state.customer ? this.state.customer.Deed_Surname ? <>
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['osa.title'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Title}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['osa.forename'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['osa.surname'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Surname}` : '' }/>
                                                </div>
                                            </div>
                                            </> : <>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['osa.business'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Deed_Business}` : '' }/>
                                                </div>
                                            </> : null
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['osa.Proprietor'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Title}` : '' }/>
                                            </div>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['osa.ClientAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['osa.DeedAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Deed_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['osa.AddressScore'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Address_Quality : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['osa.UPRN'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.UPRN : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['osa.NewConnection'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer? this.state.customer.New_Connection : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        <button className="search-button" onClick={ this.getData }>
                                            Search
                                        </button>
                                        { (!hasPermission('OSA', 'PURCHASE') && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Deed
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable} onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Deed" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['osa.VoidAgeBand'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Voids_Age_Band : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['osa.AccountType'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Type : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['osa.Consumption'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Consumption : '' }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    <DeedPDFViewer customer={this.state.customer} refresh={this.getData} />
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

export function DeedPDFViewer({ customer, refresh }) {
    useEffect(() => {
        if (!customer) return;
        useWSProduct('void_sales_alerts', 'listen', `_${customer.Account_Number}`, () => {// eslint-disable-line
            refresh()
        })

        return () => {
            // cleanup
            useWSProduct('void_sales_alerts', 'unregister', `_${customer.Account_Number}`)// eslint-disable-line
        }
    })
    return (
        customer && customer.Bought_By ? customer.DeedPDF ? <div>
            <iframe title="Deed PDF" style={{minHeight: 400, minWidth: '100%'}} src={customer.DeedPDF} />
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>We're processing your deed purchase, it'll appear here automatically once available.</p>
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>Deed available after purchase.</p>
        </div>
    )
}

