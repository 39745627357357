import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import GenericPageContainer from '../../GenericPageElements/GenericPageContainer';
import endpoints from '../../helpers/endpoints';
import request from '../../helpers/request';

export default function Alignments() {
    const [selectedProperties, setSelectedProperties] = useState({
        'product_offerings': false,
        'data_filters': false,
        'data_groupings': false,
        'company_branding': false,
        'company_translations': false,
        'release_notes': false,
    });

    const alignRequest = () => {
        request(true).post(endpoints.ADMIN_ALIGN_ENVIRONMENTS, {
            ...selectedProperties,
        }).then(r => {
            console.log(r);
            window.alert(r.data)
        }).catch(e => {
            console.log(e)
            window.alert(e.response.data)
        })
    }

    const changeSelectedProperties = (event) => {
        const checked = event.target.checked ? true : false
        if (event.target.name === "select_all") {
            const newSelectedProperties = structuredClone(selectedProperties);
            Object.keys(newSelectedProperties).forEach(function(index) {
                newSelectedProperties[index] = checked
            });
            setSelectedProperties(newSelectedProperties)
        } else {
            setSelectedProperties({...selectedProperties, [event.target.name]: checked})
        }
    }

    return (
        // <GenericPageContainer 
        //     title={'Align Environments'}
        //     titleIconClass={'fal fa-code-compare fa-2x colour-primary'}
        // >
        <div>
            <div style={{ display: 'flex', marginBottom: '1em', borderBottom: 'var(--primary) solid 2px' }}>
                <i className='fal fa-code-compare fa-2x colour-primary' style={{ alignSelf: 'center', marginRight: '1em' }} />
                <h3>Align Environments</h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{fontWeight: 'bold'}}>Select All</label>
                    <Checkbox 
                    key={'checkbox_all'}
                    color='primary'
                    checked={ Object.keys(selectedProperties).forEach(key => selectedProperties[key] === true) }
                    onChange={ changeSelectedProperties }
                    name={`select_all`} />
                </div>
                { Object.keys(selectedProperties).map(prop => {
                    const label = prop.split('_').map(propPart => propPart.charAt(0).toUpperCase() + propPart.slice(1)).join(' ')
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label>{label}</label>
                            <Checkbox 
                            key={'checkbox_' . prop}
                            color='primary'
                            checked={ selectedProperties[prop] }
                            onChange={ changeSelectedProperties }
                            name={prop} />
                        </div>
                    )
                })}
            </div>
            <button onClick={ alignRequest } className='button compact background-primary colour-white'>Align</button>
        </div>
    )
}